<template>
  <div class="setting_form h-full">
    <!-- this will be later changed to parent -->

    <div style="display: block; overflow-y: auto; height: calc(100vh - 19.2rem);">
      <div
        class="grid h-full gap-x-3 w-full grid-cols-1"
        style="grid-template-rows:1fr minmax(6rem,auto)"
      >

        <div class="flex flex-col justify-start items-start !pt-6 !py-4 gap-4 px-8 ">
          <div class="flex flex-col justify-between items-start gap-1">
            <p class="text font-bold !text-[#3C4549] text-[1rem]">
              Agency Domain Settings
            </p>
            <p class="pt-1 text !text-[0.75rem]">
              By adding your custom domain, you can personalize the domain name for example
              <strong>(app.yourbrand.com)</strong> to access the Replug platform instead of using
              <strong>(app.replug.io)</strong>. To achieve this, go to the DNS settings of your domain registrar and
              create a <strong>CNAME</strong> record with the <strong>{{ getWhitelabelDomainENV() }}</strong> value.
            </p>
          </div>
          <div class="border-t-[2px] w-full"></div>


          <div class="flex flex-col justify-between items-start gap-1">
            <p class="pt-1 text " v-if="!getSiteDetails.isWhiteLabel">
              First time setting up a custom domain? <a target="_blank"
                                                        href="https://docs.replug.io/category/166-custom-domains">Check
              out
              this guide.</a>
            </p>

            <div
              v-if="!getWhiteLabel.whiteLabel_domain_connected"
              class="flex flex-col justify-start items-start gap-1 w-full">
              <p class="py-2 text">
                <i class="fal mr-2 fa-lightbulb"></i>
                <strong>Important: </strong> make sure you point your domain DNS
                settings to <strong>whitelabel.replug.io</strong>
              </p>
            </div>

            <FloatingLabelInput
              class="mb-3 w-full"
              :errorType="
             getWhiteLabelValidations.white_label_domain.url || getWhiteLabelValidations.white_label_domain.urlLength || getWhiteLabelValidations.white_label_domain.urlValid
              ? 'danger'
              : ''
          "
              id="from_email"
              :disabled="isEditMode && getFieldEditStatus()"
              @blur="white_label_domain = focusoutConcatenation(white_label_domain)"
              v-model="white_label_domain"
              placeholder="eg. whitelabel.replug.io"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Domain Name"
              maxlength="100"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="getWhiteLabelValidations.white_label_domain.url"
                  :message="whiteLabelMessage.url"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="getWhiteLabelValidations.white_label_domain.urlValid"
                  :message="whiteLabelMessage.urlValid"
                ></InputFieldMessage>
                <InputFieldMessage
                  v-else-if="getWhiteLabelValidations.white_label_domain.urlLength"
                  :message="whiteLabelMessage.urlLength"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <div class="gap-1 rounded-md pt-2 inline-flex items-center text text-left font-poppins pb-4">
              <div v-if="!getWhiteLabel.is_email_connected" class="inline-flex items-center">
                <p class="ml-2 flex align-items-center">
                  <strong>{{ getWhiteLabel.white_label_domain }}</strong>
                  Pending verification.
                  <a class="flex ml-1 cursor-pointer !text-blue-500 underline mr-2" v-if="!getWhiteLabelLoaders.domain"
                     @click="verifyDomain()">Click here to verify</a>
                  <clip-loader v-else class="icon ml-2" style="right: 30px;" :size="'14px'"
                               :color="'#3988fe'"></clip-loader>
                </p>
              </div>
              <div v-else class="domain_connected d-inline-flex">
                <p class="ml-2">
                  <i class="fal font-weight-bold fa-check"></i> Your email connection verified successfully.</p>
              </div>
              <svg
                class="cursor-pointer mr-2"
                v-tooltip="'Edit Ip'"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click.prevent="edit()"
              >
                <path
                  d="M11.6533 1.15998C12.0759 0.737402 12.6491 0.5 13.2467 0.5C13.8443 0.5 14.4174 0.737402 14.84 1.15998C15.2626 1.58256 15.5 2.1557 15.5 2.75332C15.5 3.35093 15.2626 3.92407 14.84 4.34665L4.74889 14.4378L0.5 15.5L1.56222 11.2511L11.6533 1.15998Z"
                  stroke="#3C4549"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                class="cursor-pointer"
                v-tooltip="'Delete Ip'"
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click.prevent="clear('clear')"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.70143 1.91636C5.88803 1.72976 6.14112 1.62493 6.40501 1.62493H9.59505C9.85894 1.62493 10.112 1.72976 10.2986 1.91636C10.4852 2.10296 10.5901 2.35605 10.5901 2.61994V3.61398H5.41V2.61994C5.41 2.35605 5.51483 2.10296 5.70143 1.91636ZM4.77554 4.81398C4.78694 4.81463 4.79843 4.81496 4.81 4.81496C4.82157 4.81496 4.83306 4.81463 4.84446 4.81398H11.1556C11.167 4.81463 11.1785 4.81496 11.1901 4.81496C11.2016 4.81496 11.2131 4.81463 11.2245 4.81398H12.9826V15.3801C12.9826 15.644 12.8778 15.897 12.6912 16.0836C12.5045 16.2703 12.2515 16.3751 11.9876 16.3751H4.01249C3.7486 16.3751 3.49551 16.2703 3.30891 16.0836C3.12231 15.897 3.01748 15.644 3.01748 15.3801V4.81398H4.77554ZM4.21 3.61398V2.61994C4.21 2.03779 4.44126 1.47948 4.8529 1.06783C5.26455 0.656187 5.82286 0.424927 6.40501 0.424927H9.59505C10.1772 0.424927 10.7355 0.656187 11.1472 1.06783C11.5588 1.47948 11.7901 2.03779 11.7901 2.61994V3.61398H15.1776C15.5089 3.61398 15.7776 3.88261 15.7776 4.21398C15.7776 4.54536 15.5089 4.81398 15.1776 4.81398H14.1826V15.3801C14.1826 15.9622 13.9513 16.5205 13.5397 16.9322C13.128 17.3438 12.5697 17.5751 11.9876 17.5751H4.01249C3.43034 17.5751 2.87203 17.3438 2.46038 16.9322C2.04874 16.5205 1.81748 15.9622 1.81748 15.3801V4.81398H0.822412C0.491041 4.81398 0.222412 4.54536 0.222412 4.21398C0.222412 3.88261 0.491041 3.61398 0.822412 3.61398H2.41743H4.21ZM6.40487 7.60191C6.73624 7.60191 7.00487 7.87054 7.00487 8.20191V12.987C7.00487 13.3183 6.73624 13.587 6.40487 13.587C6.0735 13.587 5.80487 13.3183 5.80487 12.987V8.20191C5.80487 7.87054 6.0735 7.60191 6.40487 7.60191ZM10.195 8.20191C10.195 7.87054 9.92637 7.60191 9.595 7.60191C9.26363 7.60191 8.995 7.87054 8.995 8.20191V12.987C8.995 13.3183 9.26363 13.587 9.595 13.587C9.92637 13.587 10.195 13.3183 10.195 12.987V8.20191Z"
                  fill="#97304A"
                />
              </svg>

            </div>

            <div class="border-t-[2px] w-full py-3"></div>

            <FloatingLabelInput
              class="mb-3 w-full"
              :errorType="
              getWhiteLabelValidations.email_validate.email_sender_name
              ? 'danger'
              : ''
          "
              id="sender_name"
              :disabled="!isEditMode && getFieldEditStatus()"
              v-model="getWhiteLabel.email_sender_name"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Email sender name"
              maxlength="150"
              placeholder="Enter sender name"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="getWhiteLabelValidations.email_validate.email_sender_name"
                  :message="whiteLabelMessage.email_sender_name"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>
          </div>

          <div class="flex flex-col justify-between items-start gap-1">
            <p
              class="text font-bold !text-[#3C4549] text-[1rem]"
            >
              Branded Links Settings
            </p>
            <p class="pt-1 text !text-[0.75rem]">By adding your branded link domain, you can personalize the domain for example <strong>(link.yourbrand.com)</strong>
              used for shortening the URL when no custom domain is connected with the campaign.
              Just like <strong>replug.link</strong> is used for shortening the URLs when no custom domain is connected
              with the campaign.</p>
          </div>
          <div>
            <b-dropdown
              ref="branded_domain_select_menu"
              right class="w-full dropdown-menu-right hide_dropdown_caret  default_style_dropdown"
              :no-caret="true"
            >
              <div
                class=" justify-between px-[0.9rem] h-[56px] bg-[#F4F6FA] rounded-[8px] items-center gap-2.5 flex !w-[22rem]"
                slot="button-content">

                <div class="w-full flex justify-between items-center">
                  <div>
                    <p v-if=" select_domain === ''" class="text-[14px] text font-normal font-poppins">Select Branded Custom Domain</p>
                    <p class="text-[14px] text font-normal font-poppins"> {{ limitTextLength(select_domain, 30) }}</p>
                    <div v-if="getSiteDetails && getSiteDetails.custom_domain"
                         v-tooltip="domainStatusMessage(getSiteDetails.custom_domain.connectivity)"
                         class="campaign_status"
                         :class="{'active_campaign':
                     getSiteDetails.custom_domain.connectivity, 'disable_campaign': !getSiteDetails.custom_domain.connectivity}">
                    </div>
                  </div>
                  <span class="arrow_icon ml-auto"><i class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
                </div>
              </div>
              <transition name="fade">
                <ul class="w-full text-[#202324] py-1 text-[0.875rem] shadow-lg bg-white rounded-lg font-poppins">
                  <template v-if="getDomains.items.length > 0"
                            v-for="domain in getDomains.items">
                    <li @click.prevent="selectDomain(domain); $refs.branded_domain_select_menu.hide(true)"
                        v-if="domain.connectivity"
                        class="list_item_li !hover:bg-[#F4F6FA]">
                      <div class="flex items-center ">
                        <div class="content">
                          <div class="text">
                            {{ trimUrl(domain.url) }}
                          </div>

                        </div>
                      </div>

                    </li>

                  </template>
                  <li class="no_text_li">
                    <template>
                      <span v-if="getDomains.items.length === 0">You have not connected any custom domain. <br></span>
                      <hr class="my-2" v-if="getDomains.items.length !== 0">
                      <router-link :to="{name: 'custom_domains'}"><span class="py-2 text !text-[#2560D7]">Add new custom domain.</span>
                      </router-link>
                    </template>
                  </li>
                  <li v-if="getWhiteLabelValidations.custom_domain_id" class="input-error">Please select your branded
                    domain
                  </li>
                </ul>
              </transition>
            </b-dropdown>
          </div>


        </div>
      </div>
    </div>
    <div class="flex flex-col justify-between items-center !p-8 gap-4 rounded-md bg-gray-700 !bg-[#F4F6FA] m-2 col-span-3">
      <div class="flex flex-row justify-center items-center gap-2 w-full">
        <Button
          @click.prevent="fetchWhiteLabel(); clear()"
          id="cancel-request-button"
          type="button"
          customClass="px-[0.75rem] mr-2 py-[0.75rem] text-sm leading-[0.875rem] !border-[1.5px] !border-[#3C4549] !rounded-[8px] "
        >
          <template v-slot:label>Discard Changes</template>
        </Button>
        <Button
          @click.prevent="applyWhiteLabelChanges()"
          id="apply-changes"
          :disabledProp="getWhiteLabelLoaders.email"
          type="button"
          class="text-white bg-blue-600 hover:bg-blue-700"
          customClass="px-[0.75rem] py-[0.75rem] text-sm !rounded-[8px] leading-[0.875rem] text-white"
        >
          <template v-slot:label v-if="!getWhiteLabelLoaders.store">Apply Changes</template>
          <template v-else v-slot:loader>
            <Loader></Loader>
          </template>
        </Button>

      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {whiteLabelMessage} from "@/common/attributes";

export default {
  name: 'WhitelabelDomainSettings',
  data() {
    return {
      isEditMode: false,
      whiteLabelMessage: whiteLabelMessage,
      select_domain: '',
      white_label_domain: ''
    }
  },
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
    Loader: () => import("@/ui/ui-kit/v2/Loader.vue"),
    ImageUploadV2: () => import("@/ui/ui-kit/v2/ImageUploadV2.vue"),
    URLInputFloatingV2: () => import("@/ui/ui-kit/v2/URLInputFloatingV2.vue"),
    Selectdropdown: () => import("@/ui/ui-kit/v2/Selectdropdown.vue"),
  },
  async created() {

    // if whitelabel already fetched
    if (this.getWhiteLabel && this.getWhiteLabel.agency_name) {
      this.isEditMode = this.getWhiteLabel._id ? true : false
      await this.getDomainName(this.getWhiteLabel.custom_domain_id)
      this.white_label_domain = this.getWhiteLabel.white_label_domain
      return
    }

    await this.fetchWhiteLabel()
    setTimeout(async () => {
      this.isEditMode = this.getWhiteLabel._id ? true : false
      await this.getDomainName(this.getWhiteLabel.custom_domain_id)
      this.white_label_domain = this.getWhiteLabel.white_label_domain
    }, 500)
  },
  computed: {
    ...mapGetters(['getWhiteLabel', 'getWhiteLabelValidations', 'getWhiteLabelLoaders', 'getDomains'])
  },
  methods: {
    ...mapActions(['fetchWhiteLabel', 'verifyDomainRecord', 'deleteWhitelabelDomainSSL']),
    /**
     * getting whitelabel domain from env
     * @returns {string}
     */
    getWhitelabelDomainENV() {
      return process.env.VUE_APP_WHITELABEL_CUSTOM_DOMAIN_CNAME
    },
    /**
     * getting the domain messaged on the basis of status
     * @param status
     * @returns {string}
     */
    domainStatusMessage(status) {
      return status ? 'Your domain is verified and connected.' : 'Your domain is not verified.'
    },
    selectDomain(domain) {
      this.select_domain = this.trimUrl(domain.url)
      this.getWhiteLabel.custom_domain_id = domain._id
      this.getWhiteLabelValidations.custom_domain_id = !this.getWhiteLabel.custom_domain_id
      this.$refs.branded_domain_select_menu.visible = false
    },
    edit() {
      this.isEditMode = false
    },
    async verifyDomain() {

      if (this.getWhiteLabel.white_label_domain === this.white_label_domain) {
        this.isEditMode = this.getWhiteLabel.whiteLabel_domain_connected
        return
      }

      this.getWhiteLabel.white_label_domain = this.white_label_domain
      await this.deleteWhitelabelDomainSSL(this.getWhiteLabel.user_id)
      await this.verifyDomainRecord()
      this.isEditMode = this.getWhiteLabel.whiteLabel_domain_connected
    },
    clear(type = '') {
      if (type === 'clear') {
        this.isEditMode = false
        this.white_label_domain = ''
        return
      }

      if (!this.isEditMode) {
        this.white_label_domain = this.getWhiteLabel.white_label_domain
        this.isEditMode = this.getWhiteLabel.whiteLabel_domain_connected
      }
    },
    async getDomainName(domainId) {
      const domain = await this.getDomains.items.find(item => item._id === domainId)
      this.select_domain = this.trimUrl(domain.url)

    },
    /**
     * check field edit status
     * @returns {this is string[]}
     */
    getFieldEditStatus() {
      return Object.keys(this.getWhiteLabelValidations.white_label_domain).every(k => this.getWhiteLabelValidations.white_label_domain[k] === false)
    }
  },
  watch: {
    'getWhiteLabel.white_label_domain'(value) {
      this.whiteLabelCommonUrlValidation('white_label_domain')
    }
  }
}
</script>
<style scoped lang="less">
.setting_form {
  border-left: 1px solid #d0d6e5;
  min-height: 132px;

  .white_box {
    .box_inner {
      .body {
        .body-title {
          h6 {
            font-weight: bold;
            font-size: small;
          }

          p {
            font-size: small;
          }
        }

        .body-section {
          .alert {
            background: rgba(57, 136, 254, 0.1);
          }
        }

        a {
          color: #1d8bfa;

          &:hover {
            text-decoration: underline;
          }
        }

        .check_domain_section {
          .campaign_status {
            background: rgb(255, 193, 7);
            box-shadow: rgb(240 205 8 / 30%) 0px 5px 7px 0px;
            height: 12px;
            width: 12px;
            border-radius: 100%;
            display: inline-block;
            margin: 0px auto;
          }

          p {
            color: #856404;

            a {
              cursor: pointer;
              color: #856404;
              text-decoration: underline;
            }

          }

          .domain_connected {
            p {
              color: #00ad6c;
            }
          }

          .fa-pencil, .fa-trash {
            border: 1px solid lightgray;
            border-radius: 5px;
            color: lightgray;
            cursor: pointer;
          }

          .fa-pencil {
            padding: 6px;
            color: #3988FE;
            border-color: #3988FE;
          }

          .fa-trash {
            padding: 6px 8px;
            color: #f2405d;
            border-color: #f2405d;
          }

          .fa-pencil:hover {
            color: white;
            background: #3988FE;
          }

          .fa-trash:hover {
            color: white;
            background: #f2405d;
          }

        }
      }
    }
  }
}
</style>
